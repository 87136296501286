export interface ITabMenuProps {
  children: React.ReactElement[] | React.ReactElement;
  menuType: string;
  options: any;
  selectedTabIndex?: number;
  defaultTabIndex?: number;
  setCurrentTab?: any;
  isPollsMenu?: boolean;
  isWebinar?: boolean;
  tabContent?: string;
  hasBanner?: boolean;
  isStudioStage?: boolean;
  shouldRenderEngagementEndpointsFilter?: boolean;
  showZonePanel?: boolean;
}

export interface ITabMenuState {
  selectedOptionIndex: number;
}

export enum ITabMenuTypes {
  DEFAULT = 'contrastUnderline',
  CONTRAST_UNDERLINE = 'contrastUnderline',
  PRIMARY_UNDERLINE = 'primaryUnderline',
  PRIMARY_FILL = 'primaryFill',
  NO_BACKGROUND = 'noBg',
  PRIMARY_ICON_SIDE_PANEL = 'primaryIconSidePanel',
  PRIMARY_UNDERLINE_No_BG = 'primaryUnderlineNoBg',
  QUESTIONS_AND_ANSWERES = 'questionsAndAnswered',
}
